export default {
    cancel:'Cancel',
    login:{
        title:'Login',
        tip:'Please login to access more content',
        Username:'Username',
        Password:'Password',
        Forgot_password:'Forgot password',
        Login_Now:'Login Now',
        Register_Now:'Register Now',
        phone_username:'phone/username',
        Type_Password:'Type Password',
        lang:'Language',
        tip1:'Customer Service Whatsapp',
        tip2:'Customer Service Telegram',
    },
    register:{
        title:'Register',
        tip:'Please register to access more content',
        Security_PIN:'Security PIN',
        Type_Security_PIN:'Type Security PIN',
        Phone_Number:'Phone Number',
        Type_Phone_Number:'Type Phone Number',
        Invitation_Code:'Invitation Code',
        Type_Invitation_Code:'Type Invitation Code',
        Agree_with:'Agree with',
        User_Registration_Agreement:'User Registration Agreement',
        tip1:'Please agree with User Registration Agreement',
        tip2:'Please type phone/username',
        tip3:'Please type password',
        tip4:'Please type phone number',
        tip5:'Please type Invitation Code',
        tip6:'Please type security PIN',
        tip7:'Register was successful, please login',
    },
    home:{
        // title:'Pla cid',
        title:'Nativz',
        // title:'usemobile',
        Welcome:'Hi, Welcome',
        tip1:'Welcome to our platform!',
        VIP_Level:'VIP Level',
        View_More:'View More',
        Starting:'Starting',
        Certificate:'Certificate',
        Withdraw:'Withdraw',
        Deposit:'Deposit',
        Home:'Home',
        Records:'Records',
        Event:'Event',
        About_Us:'About Us',
        Credibility_Value:'Credibility Value',
        vip_title: 'VIP Level',
        vip1: {
            tip1: 'VIP 1 Optimization App',
            tip2: 'Receive a set of 40 apps data tasks',
            tip3: 'Profit of 0.5% per application',
            tip4: 'Activate with 10USDT',
        },
        vip2: {
            tip1: 'VIP 2 Optimization App',
            tip2: 'Receive a set of 50 apps data tasks',
            tip3: 'Profit of 0.6% per application',
            tip4: 'Activate with 1500USDT',
        },
        vip3: {
            tip1: 'VIP 3 Optimization App',
            tip2: 'Receive a set of 55 apps data tasks',
            tip3: 'Profit of 0.8% per application',
            tip4: 'Activate with 5000USDT',
        },
        vip4: {
            tip1: 'VIP 4 Optimization App',
            tip2: 'Receive a set of 60 apps data tasks',
            tip3: 'Profit of 1% per application',
            tip4: 'Activate with 10000USDT',
        },
    },
    start:{
        tip:'TOP APPS THIS WEEK',
        profit:'profit',
        start:'Start',
        Today_profit:'Today profit',
        tip1:'It will be updated daily. Only today\'s profits are shown here',
        Every_Ratings:'Every Ratings',
        Frozen:'Frozen',
        tip2:'Every rating\'s profit will add to total assets balance',
        Important_Notice:'Important Notice',
        Business_Hours:'Business Hours',
        tip3:'For further assistance, please contact Customer Care',
        tip4:'The system automatically updates the daily earnings',
    },
    menu:{
        tip1:'Deposit',
        tip2:'Withdraw',
        tip3:'Personal Info',
        tip4:'Transaction',
        tip5:'Wallet Usdt Bind',
        tip6:'Customer Care',
        tip7:'Account Logout',
    },
    records:{
        menu1:'ALL',
        menu2:'Submission',
        menu3:'Completed',
        menu4:'Pending',
        status1:'Submit Now!',
        status2:'Completed',
    }

}